.morph {
    animation: morph 8s ease-in-out infinite 1s;
}

@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
   }
    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
   }
    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
   }
}

.pop {
    animation: slideIn 0.3s;
}

@-webkit-keyframes slideIn {
    0% {
        transform: translateY(20px);
   }
    100% {
        transform: translateY(0);
   }
}

@keyframes slideIn {
    0% {
        transform: translateY(20px);
   }
    100% {
        transform: translateY(0);
   }
}

.hover-translate {
    transition: transform 0.3s ease;
}

.hover-translate:hover {
    transform: translate(0, -3px);
}

.fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
   }
    100% {
        opacity: 1;
   }
}