.img-java {
    background: url(../img/icons/java.svg) no-repeat center center;
    background-size: cover;
}

.invert {
    filter: invert(1);
}

i.close {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 20px;
    height: 20px;
    background: url(../img/icons/close.svg) no-repeat center center;
    background-size: cover;
    filter: invert(1);
    transition: all 0.15s ease-in-out;
}

i.close:hover {
    transform: rotate(45deg);
}

i.click {
    position: absolute;
    width: 35px;
    height: 35px;
    margin-left: -32px;
    margin-top: 32px;
    background: url(../img/gif/click.gif) no-repeat;
    background-size: cover;
    transform: rotate(25deg);
    border-radius: 100%;
}