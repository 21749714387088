.me {
    background-image: url(../img/me.png);
    background-size: 110%;
    background-position: center;
    background-color: var(--secondary-color);
    border-radius: 100%;
    box-shadow: var(--box-shadow);
    border: solid 8px #ffffff63;
}

#banner {
    height: 100vh;
    padding: 0;
    background: url(../img/gif/scroll.gif) no-repeat;
    background-position: bottom center;
    background-size: 100px;
}

.banner {
    display: flex;
    padding: 0 20%;
    justify-content: space-between;
    align-items: center;
}

.banner figure {
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner h1 {
    font-size: 3.2rem;
    margin-bottom: -15px;
}

.banner p {
    max-width: 600px;
}

@media screen and (max-width: 980px) {
    #banner {
        height: 110vh;
    }
    .banner {
        flex-direction: column-reverse;
        padding: 0 10%;
    }

    .banner>div {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* About */

.about-popup-content {
    background-image: url(../img/me-bg.png);
    width: max-content;
    background-size: 400px;
    background-position: bottom right;
    background-repeat: no-repeat;
}

/* Works */

#works {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.awssld__content {
    background-color: var(--background-color) !important;
}

.work {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.works-container {
    height: 600px;
    padding: 50px 0;
}

@media screen and (max-width: 1335px) {
    .works-container {
        height: 600px;
        padding: 0;
    }

    .work {
        width: 80%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        padding: 100px 50px;
    }

    .work-content {
        width: 100%;
    }

    .work-content h4 {
        margin: 0 !important;
    }

    .work-content p {
        background: transparent !important;
        padding: 0 !important;
        border-radius: var(--border-radius) !important;
        box-shadow: none !important;
    }

    .work-img {
        left: auto !important;
    }

    .work-tags, .work-infos {
        margin: 0 !important;
    }

}

.work-img {
    max-width: 630px;
    max-height: 315px;
    position: relative;
    z-index: 1;
    left: -50px;
    right: auto;
    border-radius: var(--border-radius);
}

.work-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.5));
    object-fit: cover;
}

.work-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 10px;
    z-index: 2;
}

.work-content p {
    background: var(--background-color);
    padding: 25px 25px;
    border-radius: var(--border-radius);
    box-shadow: 0px 5px 20px #00000062;
}

.work-content .work-infos {
    color: var(--primary-color);
}

.work-content h4,
.work-infos {
    margin: 0 25px;
}

.work-tags {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 20px;
}

.work-tags li {
    list-style: none;
    padding: 5px 15px;
    border-radius: 50px;
    background: var(--primary-color-dark);
    color: var(--on-primary-color);
    transition: opacity 0.3s ease;
    font-weight: 500;
    opacity: 0.6;
}

.work-tags li:hover {
    opacity: 0.8;
}

.infinite-carousel-container {
    overflow: hidden;
    width: 900px;
    height: 160px;
}

.infinite-carousel {
    margin-top: 5px;
    display: flex;
    animation: scroll 20s linear infinite;
    gap: 20px;
    width: max-content;
}

.infinite-carousel-item {
    flex: 0 0 300px;
    width: 300px;
    height: 150px;
    text-align: center;
    font-size: 1.5em;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
}

.infinite-carousel-item>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-weight: 700;
    opacity: 0.8;
}

.infinite-carousel-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: 0;
    outline: 0;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-300px * 6 + calc(-20px * 6)));
    }
}

@media screen and (max-width: 900px){
    .infinite-carousel-container {
        width: 100%;
    }
}

.frame {
    background-image: url('../img/frame.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 600px;
    height: 600px;
}

.frame p {
    padding: 0 150px;
}

.timeline {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 33px;
}

.timeline-arrow {
    width: 30px;
    height: 30px;
    background-image: url('../img/icons/arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(1);
    margin-bottom: -55px;
    margin-left: -2px
}

.timeline-item:nth-of-type(odd) {
    grid-column: 1;
    grid-row: 1;
    border-right: 3px solid var(--on-background-color);
    text-align: right;
}

.timeline-item:nth-of-type(even) {
    grid-column: 2;
    grid-row: 2;
    border-left: 3px solid var(--on-background-color);
    text-align: left;
    margin-left: -3px;
}

.timeline-item {
    padding: 25px;
}