.skill-large-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 20px;
    max-width: 1200px;
    width: 100%;
}

.skill-large {
    background-color: var(--primary-color-dark);
    padding: 35px;
    border-radius: var(--border-radius);
    min-height: 320px;
}

.skill-large-icon {
    filter: invert(1);
    width: 65px;
    height: 65px;
}

.skill-large h3 {
    font-size: 25px;
}