@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lexend+Giga:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Constants */

body {
    --primary-color: #3BBA9C;
    --primary-color-light: #4fc3a1;
    --primary-color-dark: #2b8a71;
    --secondary-color: #707793;
    --secondary-color-light: #9e9e9e;
    --secondary-color-dark: #707793;
    --on-primary-color: #fff;
    --on-secondary-color: #fff;
    --background-color: #272934;
    --on-background-color: #fff;
    --surface-color: #fff;
    --on-surface-color: #141414;
    --error-color: #f44336;
    --on-error-color: #fff;
    --font-family: 'Montserrat', sans-serif;
    --font-family-secondary: 'Montserrat', sans-serif;
    --font-family-secondary-spacing: inherit;
    --font-size: 16px;
    --font-weight: 400;
    --line-height: 1.5;
    --border-radius: 15px;
    --box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.07);
    --transition: 0.3s;
}

/* Global styles */

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    color: var(--on-background-color);
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: var(--font-family-secondary);
    font-weight: 700;
    letter-spacing: var(--font-family-secondary-spacing);
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 32px;
}

h4 {
    font-size: 26px;
}

h5 {
    font-size: 21px;
}

h6 {
    font-size: 17px;
}


p {
    margin: 0;
    max-width: 600px;
}

span {
    font-family: var(--font-family);
}

a {
    font-size: var(--font-size);
    color: var(--on-background-color);
    text-decoration: none;
    transition: var(--transition);
}

a:hover:not(.button, .button-secondary, .button-surface) {
    opacity: 0.8;
    color: var(--primary-color);
}

section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 100px 0;
}

section:nth-child(even) {
    background-color: var(--surface-color);
    color: var(--on-surface-color);
}

button {
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-family: var(--font-family);
}

/* Usefull classes */

/* Flexbox */

.flex {
    display: flex;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.align-between {
    align-items: space-between;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-row-responsive {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-row-responsive>* {
    flex: 1 1 100%;
}

.gap-100 {
    gap: 100px;
}

.gap-50 {
    gap: 50px;
}

.gap-20 {
    gap: 20px;
}

.gap-10 {
    gap: 10px;
}

.gap-5 {
    gap: 5px;
}

/* Text */

.text-center {
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-italic {
    font-style: italic;
}

.text-underline {
    text-decoration: underline;
}

.text-justify {
    text-align: justify;
}

.text-semibold {
    font-weight: 500;
}

.text-bold {
    font-weight: 600;
}

.text-700 {
    font-weight: 700;
}

.text-extrabold {
    font-weight: 800;
}

.text-light {
    font-weight: 300;
}

.text-black {
    font-weight: 900;
}

.text-italic {
    font-style: italic;
}

.text-primary {
    color: var(--primary-color);
}

.text-secondary {
    color: var(--secondary-color);
}

.dotted-underline {
    text-decoration: underline dotted;
}

.dotted-underline:hover {
    text-decoration: underline;
}

/* Common styles */

.container {
    padding: 0 20px;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.max-content {
    width: max-content;
}

.pointer {
    cursor: pointer;
}

.container>h1 {
    text-align: center;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--background-color);
    position: fixed;
    z-index: 11;
}

.header .logo {
    font-size: 20px;
    font-weight: bolder;
    padding: 0 20px;
}

.header .logo>a::after {
    content: "Anas O.";
}

.header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.header nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    gap: 20px;
}

.header nav a {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    transition: 0.3s;
}

.progress-container {
    width: 100%;
    height: 5px;
}

.progress-bar {
    height: 5px;
    background: var(--primary-color);
    width: 0%;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: var(--primary-color);
    color: var(--on-primary-color);
    transition: var(--transition);
    outline: 2px solid var(--primary-color);
    width: max-content;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
}

.button:hover,
.button.active {
    background-color: transparent;
    color: var(--primary-color);
}

.button-secondary {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: transparent;
    outline: 2px solid var(--primary-color);
    color: var(--primary-color);
    transition: var(--transition);
    width: max-content;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
}

.button-secondary:hover,
.button-secondary.active {
    background-color: var(--primary-color);
    color: var(--on-primary-color);
}

.button-surface {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: var(--surface-color);
    color: var(--on-surface-color);
    transition: var(--transition);
    outline: 2px solid var(--surface-color);
    width: max-content;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
}

.button-surface:hover,
.button-surface.active {
    background-color: transparent;
    color: var(--surface-color);
}

.button-icon, .button.icon, .button-surface.icon, .button-secondary.icon {
    display: flex;
    gap: 7px;
    align-items: center;
}

.button-icon {
    padding: 10px 20px;
    border-radius: 50px;
    background-color: var(--primary-color);
    color: var(--on-primary-color);
    transition: var(--transition);
    outline: 2px solid var(--primary-color);
    width: max-content;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
}

.button-icon:hover,
.button-icon.active {
    background-color: transparent;
    color: var(--primary-color);
}

@media screen and (max-width: 980px) {}

@media screen and (max-width: 600px) {

    .header-container {
        flex-direction: column;
        justify-content: center;
        align-self: center;
    }

    .header nav {
        justify-content: space-between;
        gap: 0;
    }

    .header .logo {
        padding-top: 10px;
    }

    .header .logo>a::after {
        content: "Anas Ouhdda";
    }

    .header nav a:nth-of-type(1) {
        display: none;
    }
}

.context-bubble {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    text-align: center;
    color: var(--on-surface-color);
    padding: 6px 12px 6px 12px;
    background-color: var(--surface-color);
    white-space: nowrap;
    font-size: 15px;
    border-radius: 20px;
    opacity: 0;
    transition: all 0.3s ease;
}

*:hover>.context-bubble {
    opacity: 0.8;
}

.footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    padding: 100px 0;
    color: var(--on-primary-color);
    font-weight: 600;
}

.footer .container {
    padding: 0 10%;
    max-width: 1200px;
}

.footer a:hover {
    color: var(--on-primary-color);
    text-decoration: underline;
}

.social-links {
    display: flex;
    gap: 5px;
}

.social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    background-color: var(--surface-color);
    padding: 5px;
    transition: var(--transition);
    box-shadow: var(--box-shadow);
}

.social-links a img {
    width: 25px;
    height: 25px;
}

.social-links a:hover {
    transform: translate(0, -3px);
}

/* PopUp Component */

.popup-content {
    margin: auto;
    background: var(--background-color);
    width: 50%;
    padding: 35px;
    border-radius: var(--border-radius);
    overflow-y: auto;
    max-height: 80vh;
}

.popup-arrow {
    color: var(--on-background-color);
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}


@media all and (max-width: 520px) {
    .awssld__controls {
      visibility: visible !important; 
    } 
}

.tooltip-content {
    padding: 5px 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    width: max-content !important;
    animation: fadeIn ease-in 1;
    border-radius: 8px;
    border: 1px solid var(--secondary-color);
}

code {
    background-color: #242424;
  padding: 2px 5px;
    padding-top: 2px;
    padding-right: 5px;
    padding-bottom: 2px;
    padding-left: 5px;
  border-radius: 5px;
}

.mt-100 {
    margin-top: 100px;
}

.opacity-50 {
    opacity: 0.5;
}