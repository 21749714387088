.article {
  padding: 20px;
}

.article-side {
  max-width: 210px;
}

.article-side ul {
  padding: 0;
}

.article-side ul li {
  list-style: none;
  padding: 2.5px 0;
  width: 100%;
}

.article-side ul li a {
  font-size: 14px;
  color: #c6c8d7;
  display: block;
}

.article-side .sidetitle {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
}

.article-side-content {
  position: fixed;
  max-width: 210px;
}

.article-content {
  max-width: 900px;
}

.article-content .title {
  font-size: 32px;
  font-weight: 800;
}

.article-content ul.tags {
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0;
}

.article-content ul.tags li {
  list-style: none;
  padding: 5px 15px;
  border-radius: 50px;
  background: var(--primary-color-dark);
  color: var(--on-primary-color);
  transition: opacity 0.3s ease;
  font-weight: 500;
  opacity: 0.6;
}

.article-content ul.tags li:hover {
  opacity: 0.8;
}

.article-content h4 {
  font-size: 1.4rem;
  font-weight: 700;
}

.article-content h5 {
  font-size: 1.2rem;
  font-weight: 600;
}

.article-content h6 {
  font-size: 1.1rem;
  font-weight: 700;
}

.article-content p {
  max-width: max-content;
}

.article-content > div {
  margin-top: 20px;
}

.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
  margin: 20px 0 10px 0;
}

.article-content > div > img,
.article-content > p > img {
  margin-top: 20px;
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: rgb(25 15 15 / 25%) 0px 9px 15px 5px;
  text-align: center;
}

.article-content .img-caption {
  display: block;
  color: #c6c8d7;
  font-style: italic;
  font-size: 0.9rem;
}

@media (max-width: 850px) {
  .article {
    flex-direction: column;
    justify-content: start;
  }
}

@media (max-width: 1186px) {
  .article-side-content {
    position: relative;
  }
}
