/* Works */

#works {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.awssld__content {
    background-color: var(--background-color) !important;
}

.work {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.work:nth-of-type(odd) {
    flex-direction: row;
}

.work:nth-of-type(odd) .work-img {
    left: auto;
    right: -25px;
}

.work:nth-of-type(odd) .work-content {
    justify-content: flex-end;
}

.works-container {
    height: 600px;
    padding: 0;
}

.awssld__bullets button {
    background-color: var(--primary-color);
    opacity: 0.3;
    width: 14px;
    height: 14px;
}

.awssld__bullets--active {
    background-color: var(--primary-color) !important;
    opacity: 1 !important;
    transform: scale(1.3) !important;
}

@media screen and (max-width: 500px) {
    .work-video {
        position: absolute !important;
        margin: auto !important;
        filter: brightness(0.2) !important;
    }
}
@media screen and (max-width: 1335px) {
    .work {
        width: 80%;
        padding: 10% 40px !important;
    }

    .work-content {
        padding: 0 !important;
    }

    .work-img {
        width: 90% !important;
        height: 60% !important;
        max-width: 800px !important ;
        max-height: 400px !important    ;
        position: absolute !important;
        left: 0 !important;
        right: 0 !important;
        margin: auto !important;
    }

    .work-img img {
        filter: brightness(0.2) !important;
    }
}

.work-video {
    height: 450px;
}

.work-img {
    max-width: 630px;
    height: 315px;
    position: relative;
    z-index: 1;
    left: -50px;
    right: auto;
    border-radius: var(--border-radius);
    transition: all 0.3s ease;
    background-color: var(--surface-color);
}

.work-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.5));
    object-fit: cover;
}

.work-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 10px;
    z-index: 2;
}

.work-content p {
    background: var(--background-color);
    padding: 25px 25px;
    border-radius: var(--border-radius);
    box-shadow: 0px 5px 20px #00000062;
}

.work-content .work-infos {
    color: var(--primary-color);
}

.work-content h4,
.work-infos {
    margin: 0 25px;
}

.work-tags {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 20px;
}

.work-tags li {
    list-style: none;
    padding: 5px 15px;
    border-radius: 50px;
    background: var(--primary-color-dark);
    color: var(--on-primary-color);
    transition: opacity 0.3s ease;
    font-weight: 500;
    opacity: 0.6;
}

.work-tags li:hover {
    opacity: 0.8;
}